import React from 'react';
import './Servicios.css';
import { FaUserNurse,FaRegHospital,FaFileMedicalAlt,FaUserCog,FaLaptopMedical,FaChartLine,FaMoneyCheckAlt} from 'react-icons/fa';
function Servicios() {
    return (
        <div id="herramientas" className="text-center">
           <div className="containerherramientas">
               <div className="secciontitulo">
                <h1 className="tituloherramientas">
                Beneficios
                </h1>
                <hr className="separadorherramientas"/>
                <p className="parrafoherramientas">Tus servicios de salud en las mejores manos.</p>
               </div>

               <div className="flex-container-herramientas">
                   <div className="row-herramientas">
                       <div className="div-herramienta">
                       <FaUserNurse className="caract-herramientas"/>
                       <h3 className="h3-servicios">Garantía</h3>
                       <p className="desc-herramientas">Todos nuestros servicios cuentan con garantía, al contar con nuestros servicios también obtendras una garantía extendida por lo que te brindamos.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaFileMedicalAlt className="caract-herramientas"/>
                       <h3 className="h3-servicios">Cumplimiento</h3>
                       <p className="desc-herramientas">Los altos estandares son necesarios, por eso en Agentes Biomédicos garantizamos el cumplimiento de nuestros servicios con las normas de habilitación actuales.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaMoneyCheckAlt className="caract-herramientas"/>
                       <h3 className="h3-servicios">Facilidad de Pago</h3>
                       <p className="desc-herramientas">Apoyamos la presación de tus servicios de salud con facilidades de pago, nuestra misión es que tus servicios sean seguros y confiables.</p>
                       </div>
                       
                       
                   </div>
                   <div className="row-herramientas">
                   <div className="div-herramienta">
                       <FaUserCog className="caract-herramientas"/>
                       <h3 className="h3-servicios">Soporte</h3>
                       <p className="desc-herramientas">Nuestro personal estará disponible para brindarte el soporte necesario en el menor tiempo posible. Nuestro tiempo de respuesta beneficiará a la prestación de tus servicios.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaRegHospital className="caract-herramientas"/>
                       <h3 className="h3-servicios">Seguridad</h3>
                       <p className="desc-herramientas">Tus servicios se encontrarán en las mejores manos. Nuestro personal certificado se encargará de que tus servicios sean más seguros gracias a nosotros.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaChartLine className="caract-herramientas"/>
                       <h3 className="h3-servicios">Rentabilidad</h3>
                       <p className="desc-herramientas">Adquieriendo nuestros servicios notarás que la rentabilidad de tu institución aumentará ya que no se detendrán por algun tipo de fallas o hallazgos. </p>
                       </div>
                       
                   </div>
               </div>

           </div>
        </div>
    )
}

export default Servicios
