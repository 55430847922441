import React, {  useEffect, useState  }  from 'react';
import './BlogCategories.css'; 
import { useParams } from "react-router-dom";
import PostCard from './PostCard';
import Recientes from './Recientes';
import { request } from 'graphql-request';
import Footer from './Footer';

import MarcasCarousel from './MarcasCarousel';
import { Link } from 'react-router-dom'; 

function BlogCategories() {
    const { categorie} = useParams();
    const [posts, setPosts] = useState(null);
    const [recentPost,setRecentPosts]=useState(null); 

    useEffect(() => {
        const fetchPosts = async () => {
            const { posts } = await request(
              'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
              `
            { 
                posts(orderBy: createdAt_ASC, where: {categories_every: {name: "${categorie}"}}) {
                    title
                    createdAt
                    featuredImage {
                      url
                    }
                    featuredImage {
                        url
                      }
                      id
                      slug
                      excerpt
                      author {
                        name
                        photo {
                          url
                        }
                      }
                  }
                 
            }
          `
            );
    
      
            setPosts(posts);
           
          };

          const fetchRecentPost = async () => {
            const { posts} = await request(
              'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
              `
            { 
                posts(where: {categories_every: {name: "${categorie}"}}, orderBy: createdAt_ASC,last:5) {
                    author {
                      name
                      photo {
                        url
                      }
                    }
                    title
                    createdAt
                    id
                    featuredImage {
                        url
                      }
                  }
            }
          `
            );
    
      
            setRecentPosts(posts);
           
          };
          fetchRecentPost();
          fetchPosts();
    }, []); 
    console.log(posts);


  return (<div>
  {!posts||!recentPost?(<h1 className='cargandocontent'>Cargando Contenido</h1>):(
             <div className='divmadreblog'>
                    <div className='divhijo1blog'>
                     <div className='divtituloblog'>
                         
                          
                     <h1 className='titulo_blog_principal'>
                     <Link to={`/blog`} className="linksectioncategorie">Blog de Noticias  </Link> / {categorie}
                        
                    </h1>
                    
                    
                    
                    <hr className="separadorgradienteabout"/>
                     </div>   

                     <div className='divblogsection'>

                     {posts.map((post)=>(<PostCard post={post} key={post.title}/>))} 
         
            
                    </div> 

                     </div>

                     <div className='divhijo2blog'>
                     <div className='divcategorias'>
                        <div className='divtitulocategorias'>
                                    <h1 className='titulo_blog_secundario'>Publicados Recientemente</h1>
                                    <hr className="separadorgradienteabout"/>
                                    </div> 

                        {recentPost.map((recentPost)=>(<Recientes post={recentPost} key={recentPost.title}/>))} 
                            
                        
                        </div>

                        <div className='divcategorias'>
                            <div className='divtitulocategorias'>
                                        <h1 className='titulo_blog_secundario'>Nuestras Marcas</h1>
                                        <hr className="separadorgradienteabout"/>
                                        </div> 
                            <div className='carouseldiv'>
                            <MarcasCarousel></MarcasCarousel>
                            </div>            
        
                        </div>

                     </div>


             </div>)

  }

<Footer></Footer>
   </div>
  
  )
}

export default BlogCategories