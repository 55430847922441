import React from 'react';
import Button from '../../Elements/Button';
import './Contacto.css'; 
import {FaPhoneAlt,FaMapSigns,FaMailBulk,FaFacebookF,FaYoutube,FaTwitter} from 'react-icons/fa';

function Contacto() {
    return (
        <div id="contacto">
            <div className="contact-container">
                <div className="form-container">
                    <div className="form-display">
                        <div className="form-divtitulo">
                            <h1 className="form-titulo">Ponte en Contacto</h1>
                            <hr className="form-separador"/>
                            <p className="form-descripcion">Utiliza este espacio para enviarnos un mensaje, solo debes completar el siguiente formulario y te responderemos lo más pronto.</p>
                        </div>

                        <form name="formatoenviar"className="form-form">
                            <div className="form-fila">
                                <div className="form-divinputname">
                                <input className="form-inputname" type="text" id="name" placeholder="Nombre">
                                </input>
                                <p className="helpertext"></p>
                                </div>
                                <div className="form-divinputemail">
                                <input className="form-inputemail" type="email" id="email" placeholder="Email">
                                </input>
                                <p className="helpertext"></p>

                                </div>

                            </div>

                            <div className="form-fila">
                                <div className="form-divinputtext">
                                    <textarea className="form-textarea" name="mensaje" id="mensaje" rows="4" required placeholder="Escribe tu mensaje aquí"> 
                                    </textarea>
                                    <p className="helpertext"></p>
                                </div>
                            </div>
                            <div className="div-button">
                          
                                

                            </div>


                        </form>
                        <a href="mailto:agentesbiomedicos@gmail.com?Subject=Interesado%20en%20sus%20servicios">
                            <Button  buttonSize="btn--wide" buttonColor='blue'>Enviar</Button>

                            </a>
                    </div>

                </div>
                <div className="info-container">
                    <div className="contact-item">
                        <h3 className="contact-tituloinfo">Información</h3>
                        <p className="contact-pitem">
                            <span className="contact-spanitem">
                                <FaMapSigns className="contacticon"/>
                                Dirección
                            </span>
                            Cali,Colombia
                        </p>

                    </div>
                    <div className="contact-item">
                        <p className="contact-pitem">
                            <span className="contact-spanitem">
                                <FaPhoneAlt className="contacticon"/>
                                Teléfono
                            </span>
                            +57-3218472298
                        </p>

                    </div>
                    <div className="contact-item">
                        <p className="contact-pitem">
                            <span className="contact-spanitem">
                                <FaMailBulk className="contacticon"/>
                                E-mail
                            </span>
                           comercial@agentesbiomedicos.co 
                        </p>

                    </div>

                </div>

                

                </div>
                <div className="contact-socialcontainer">
                    <div className="contact-socialrow">
                        <ul className="contact-ul">
                            <li className="contact-li">
                            <div className="contact-bordersocial">
                                <a href="http://www.facebook.com/agentesbiomedicos.co" target="blank">
                                    <FaFacebookF className="contact-socialicon"/>
                                </a>
                                </div>
                            </li>
                            <li className="contact-li">
                                <div className="contact-bordersocial">
                                <a href="https://www.youtube.com/watch?v=qUi9rmwyXP4&t=11s&ab_channel=AgentesBiomedicos" target="blank">
                                    <FaYoutube className="contact-socialicon"/>
                                </a>
                                </div>
                            </li>
                            <li className="contact-li">
                                <div className="contact-bordersocial">
                                <a href="https://twitter.com/agentesbiomedi1" target="blank">
                                    <FaTwitter className="contact-socialicon"/>
                                </a>
                                
                                </div>
                               
                            </li>
                            
                        </ul>

                    </div>

            </div>
            <div id="footer">
          <div className="container-text-center">
            <p>
              &copy; 2020 Plataforma Web Diseñada por Bioinnova Technologies S.A.S. Todos los derechos reservados{" "}
              
            </p>
          </div>
        </div>
            
        </div>
    )
}

export default Contacto
