import React from 'react'; 
import './Footer.css'; 

import {FaPhoneAlt,FaMapSigns,FaMailBulk,FaWhatsapp,FaFacebookF,FaInstagram,FaYoutube,FaTwitter} from 'react-icons/fa';
function Footer() {
  return (
    <div className='divfootermain'>
        <div className='divfooterblock'>
            <div className='divtituloblock'>

            <h1 className='tituloblock'>Contacto</h1>
            <hr className="separadorgradienteabout"/>

            </div>

            <div className='divcontentblock'>
            <div className="contact-item">
                        <p className="txtcontactfooter">
                        <FaMailBulk className="contacticon"/>
                           comercial@agentesbiomedicos.co 
                        </p>

                    </div>
            <div className="contact-item">
                        <p className="txtcontactfooter">
                        <FaPhoneAlt className="contacticon"/>
                           +57-3218472298 +57-3044029113
                        </p>

                    </div>
             <div className="contact-item">
                        <p className="txtcontactfooter">
                        <FaWhatsapp className="contacticon"/>
                           +57-3116074973 +57-3044029111
                        </p>

                    </div>
                    <div className="contact-item">
                        <p className="txtcontactfooter">
                        <FaMapSigns className="contacticon"/>
                           Cali, Colombia
                        </p>

                    </div>

            </div>

            
            
        </div>

        <div className='divfooterblock'>
        <div className='divtituloblock'>

<h1 className='tituloblock'>Mapa del Sitio</h1>
<hr className="separadorgradienteabout"/>

</div>
<div className='divcontentblock'>
<div className="contact-item">
                        <a className="txtcontactfooter"  href='/'>
                           Inicio
                        </a>

                    </div>

                    <div className="contact-item">
                        <a className="txtcontactfooter"  href='/blog'>
                           Blog de Noticias
                        </a>

                    </div>

                    <div className="contact-item">
                        <a className="txtcontactfooter"  target="_blank" href='https://bioinnovatechnologiessas.vendaenlinea.co/'>
                           Tienda En Linea
                        </a>

                    </div>
</div>

        </div>



        <div className='divfooterblock'>
        <div className='divtituloblock'>

<h1 className='tituloblock'>Redes Sociales</h1>
<hr className="separadorgradienteabout"/>

</div>
<div className='divcontentblock'>

<div className="contact-item">
<a className="txtcontactfooter" target="_blank" href='https://www.facebook.com/agentesbiomedicos.co'>
                        <FaFacebookF className="contacticon"/>
                           @agentesbiomedicos.co
                        </a>

                    </div>
                    <div className="contact-item">
                        <a className="txtcontactfooter" target="_blank" href='https://www.instagram.com/agentesbiomedicos.co'>
                        <FaInstagram className="contacticon"/>
                           @agentesbiomedicos.co
                        </a>

                    </div>
                    <div className="contact-item">
                        <a className="txtcontactfooter" target="_blank" href='https://www.youtube.com/channel/UC52EUhoq1cWctB1qLGAx-_w'>
                        <FaYoutube className="contacticon"/>
                           @agentesbiomedicos.co
                        </a>

                    </div>
</div>


        </div>
    </div>
  )
}

export default Footer