import React from 'react'
import './Caracteristicas.css'
import {FaLaptopMedical,FaUsersCog,FaTemperatureHigh,FaFileSignature,} from 'react-icons/fa';

function Caracteristicas() {
    return (
        <div id="caracteristicas" className="text-center">
             <div className="caract-titulo"> 
             <h1 className="caract-titulo-h1">Somos eficiencia para tus servicios</h1>
             <hr className="separadorgradiente"></hr>
             </div>

            <div className="caract-container">
           
            <div className="row">

            <div className="caracter">
            <FaLaptopMedical className="caract-icono"/>
            <h3 className="titulo-caracteristica">Tecnología</h3>
            <p className="caract-descripcion">Encuentra la mejor tecnología biomédica del mercado con nosotros, tendrás disponible una red de proveedores.</p>
           
            </div> 

            <div className="caracter">
            <FaUsersCog className="caract-icono"/>    
          <h3 className="titulo-caracteristica">Mantenimiento</h3>
            <p className="caract-descripcion">Realizamos el mantenimiento preventivo de tu tecnología biomédica para que tus servicios sean más seguros.</p>
            </div>
            <div className="caracter">
            <FaTemperatureHigh className="caract-icono"/>
            <h3 className="titulo-caracteristica">Calibración</h3>
            <p className="caract-descripcion">Ejecutamos la calibración de tus equipos biomédicos con patrones trazables y laboratorios certificados.</p>
           
            </div>
            <div className="caracter">
            <FaFileSignature className="caract-icono"/>
            <h3 className="titulo-caracteristica">Asesorias</h3>
            <p className="caract-descripcion">Elaboramos la documentación establecida por la resolución 3100, contarás con un equipo de auditores.</p>
           
            </div>

           
            
            </div>

            </div>
            
            
        </div>
    )
}

export default Caracteristicas
