import React,{  useEffect, useState  } from 'react';
import { useParams } from "react-router-dom";
import { request } from 'graphql-request';
import ReactHtmlParser from 'react-html-parser'; 
import './BlogPost.css';
import Recientes from './Recientes';
import MarcasCarousel from './MarcasCarousel';
import Footer from './Footer';

export default function BlogPost() {
    const { title} = useParams();
    const [post, setPost] = useState(null);
    const [recentPost,setRecentPosts]=useState(null); 

    useEffect(() => {

        const fetchPost = async () => {
            const { post } = await request(
              'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
              `
            { 
                
                post(where: {id: "${title}"}) {
                    content {
                        html
                    }
                    title
                    featuredImage {
                        url
                      }
                      createdAt
                      author {
                        name
                      }
                      categories {
                        name
                      }
                  }
            }
          `
            );
    
      
            return setPost(post);
           
          };
          fetchPost();   

          const fetchRecentPost = async () => {
            const { posts} = await request(
              'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
              `
            { 
                posts(orderBy: createdAt_ASC last:4) {
                    title
                    createdAt
                    featuredImage {
                      url
                    }
                    id
                    slug
                    author {
                        name
                      }
                  }
            }
          `
            );
    
      
           return setRecentPosts(posts);
        }

        fetchRecentPost();
      
       
        return ()=>{console.log('cleanup')};
    }, [title]);

    

  return (
    <div className='divmainblogpost'>

   
    {!post||!recentPost?(<h1 className='cargandocontent'>Cargando Contenido</h1>):(
        <div className='divmadreblogpost'>

        <div className='divblogcontent'>
        <div className='divtituloblog'>
                    <h1 className='titulo_blog_principal'>{post.title}</h1>
                    <hr className="separadorgradienteabout"/>
                     </div>   

        <div className='divimagenblogpost'>

        <img className='imgblogpost' src={post.featuredImage.url}></img>    

        </div>

        <div className='divcontentpost'>

   
            
        <p className="paradescriptionblog">{ ReactHtmlParser (post.content.html) } </p>
        </div>



        </div>
        <div className='divblogcontent2'>
        <div className='divrecientesblogpost'>

<div className='divtitulocategorias'>
            <h1 className='titulo_blog_secundario'>Publicados Recientemente</h1>
            <hr className="separadorgradienteabout"/>
             </div> 

             {recentPost.map((recentPost)=>(<Recientes post={recentPost} key={recentPost.title}/>))} 

    

</div>

<div className='divmarcasblogpost'>
        <div className='divtitulocategorias'>
                    <h1 className='titulo_blog_secundario'>Nuestras Marcas</h1>
                    <hr className="separadorgradienteabout"/>
                     </div> 
         <div className='carouseldiv'>
         <MarcasCarousel></MarcasCarousel>
        </div>            
        
        </div>

        </div>
       


        </div>


    )}

    <Footer></Footer>
     </div>
   
  )
}

