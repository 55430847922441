import React from 'react';
import './Header.css';
import {Link} from 'react-router-dom';
import Button from '../../Elements/Button';
import imgsrc from '../../../utils/ablogo.svg';

function Header() {
    return (
       
        <div className='header-section'>
        <div className='header-overlay'>
      
        <div className="header-container">

        <div className="home__hero-text-wrapper">
                     <img src={imgsrc} alt="logo" className='logoheader'/>
                    
                    <p className='home__hero-subtitle'>Encuentra la mejor tecnología médica</p>
                    
                    
                
                
                    
                    </div>
       

        </div>

        </div>
            
        </div>
    )
}

export default Header
