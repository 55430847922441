import React, { Component } from 'react'
import About from './About/About';
import Caracteristicas from './Caracteristicas/Caracteristicas';
import Contacto from './Contacto/Contacto';

import Header from './Header/Header';
import Servicios from './Servicios/Servicios';

export class Home extends Component {
    render() {
        return (
            <div>
                <Header></Header>
                <Caracteristicas/>
                <About/>
                <Servicios/>
                <Contacto></Contacto>
              
            </div>
        )
    }
}

export default Home
