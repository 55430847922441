import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';

import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import { Blog } from './components/Blog/Blog';
import BlogPost from './components/Blog/BlogPost';
import BlogCategories from './components/Blog/BlogCategories';


function App() {
  return (
    <Router>
    
    <Switch>   
    <AppRoute exact path="/" component={Home} layout={Navbar} />   
    <AppRoute exact path="/blog" component={Blog} layout={Navbar}></AppRoute>
    <AppRoute exact path="/blog/:title" component={BlogPost} layout={Navbar}></AppRoute>
    <AppRoute exact path="/blog/categorias/:categorie" component={BlogCategories} layout={Navbar}></AppRoute>
    </Switch>
    </Router>
  );
}

export default App;
 