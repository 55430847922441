import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './MarcasCarousel.css'; 
import img from '../../utils/logo1.png';
import img2 from '../../utils/logo2.png';
import img3 from '../../utils/logo3.png';
import img4 from '../../utils/logo4.png';


const handleDragStart = (e) => e.preventDefault(); 

const items = [
    <img src={img} className="imgcarousel" onDragStart={handleDragStart} role="presentation" />,
    <img src={img2}className="imgcarousel" onDragStart={handleDragStart} role="presentation" />,
    <img src={img3} className="imgcarousel" onDragStart={handleDragStart} role="presentation" />,
    <img src={img4} className="imgcarousel" onDragStart={handleDragStart} role="presentation" />,
  ];


export default function MarcasCarousel() {
  return (
    <AliceCarousel mouseTracking items={items} autoPlay='true' disableButtonsControls='true' infinite='true'
    animationDuration={800}
    />
  )
}
