import React, { Component,useState, useEffect } from 'react';
import Button from '../Elements/Button'
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes,FaShoppingCart,FaNewspaper } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import imgsrc from '../../utils/abh.svg';
import MyButton from '../../utils/MyButton';

 

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };
  
    useEffect(() => {showButton()}, []);
  
    window.addEventListener('resize', showButton);
  
    return (
      <>
        <IconContext.Provider value={{ color: '#fff' }}>
          <nav className='navbar'>
            <div className='navbar-container container'>
              <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              
              <img src={imgsrc} alt="logo" className='logonavbar'/>
              
              </Link> 
              <div className='menu-icon' onClick={handleClick}>
                {click ? <FaTimes color="#0082CC"/> : <FaBars color="#0082CC"/>}
              </div>
               <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                
                
               
                <li className='nav-btn'>
                  {button ? (
                    <div className='button-container'>
                      <div>
                      <Link to='/blog' className='btn-nav'>
                      <MyButton tip="Blog de Noticias" >
                        <FaNewspaper color="#0082CC" className="icon-ingresar"/>
                     </MyButton>
                      </Link>
                      
                      </div>

                   
                      
                    
                    </div>
                    
                   
              
                  ) : (
                      <>
                    <div className='divnavbarmobile'>
                  ¿

                    <Link to='/blog' className='btn-nav'>
                    <Button
                        buttonStyle='btn--outline'
                        buttonSize='btn--mobile'
                        onClick={closeMobileMenu}
                      >
                       Blog de Noticias
                      </Button>
                      </Link>
                
                    </div>
                   
                   
                  
                      </>
                    
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </IconContext.Provider>
      </>
    );
  }
  
  export default Navbar;
  
  