import React, {  useEffect, useState  } from 'react';
import './Blog.css'; 
import PostCard from './PostCard';
import { request } from 'graphql-request';
import Categorias from './Categorias';
import Recientes from './Recientes';
import MarcasCarousel from './MarcasCarousel';
import Footer from './Footer';


export function Blog (){

    const [posts, setPosts] = useState(null);
    const [recentPost,setRecentPosts]=useState(null); 
    const [categories,setCategories]=useState(null);

    useEffect(() => {
      const fetchPosts = async () => {
        const { posts } = await request(
          'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
          `
        { 
            posts (last:6){
                title
                id
                content {
                  text
                }
                author {
                  name
                  photo {
                    url
                  }
                }
                createdAt
                categories {
                  name
                }
                featuredImage {
                  url
                }
                excerpt
              }
        }
      `
        );

  
        setPosts(posts);
       
      };

      const fetchCategories = async () => {
        const { categories } = await request(
          'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
          `
        { 
            categories {
                name
                id
                slug
              }
        }
      `
        );

  
        setCategories(categories);
       
      };

      const fetchRecentPost = async () => {
        const { posts} = await request(
          'https://api-us-east-1.graphcms.com/v2/ckydoxg6a07bc01xqahvt3530/master',
          `
        { 
            posts(orderBy: createdAt_ASC last:4) {
                title
                createdAt
                featuredImage {
                  url
                }
                id
                slug
                author {
                    name
                  }
              }
        }
      `
        );

  
        setRecentPosts(posts);
       
      };


      

      fetchRecentPost();
      fetchCategories();
      fetchPosts();
   
    }, []);
/*
    console.log(posts);
    console.log(categories);
    console.log(recentPost);
*/


    return( 
    
   
    
    <div>
         {!posts||!recentPost||!categories?(<h1 className='cargandocontent'>Cargando Contenido</h1>):(
             <div className='divmadreblog'>
                     <div className='divhijo1blog'>
                     <div className='divtituloblog'>
                    <h1 className='titulo_blog_principal'>Blog de Noticias</h1>
                    <hr className="separadorgradienteabout"/>
                     </div>   

                     <div className='divblogsection'>

                     {posts.map((post)=>(<PostCard post={post} key={post.title}/>))} 
         
            
                    </div> 

                     </div>

             <div className='divhijo2blog'>
             <div className='divcategorias'>
             <div className='divtitulocategorias'>
                    <h1 className='titulo_blog_secundario'>Categorias</h1>
                    <hr className="separadorgradienteabout"/>
                     </div> 
             {categories.map((categorie)=>(<Categorias categorie={categorie} key={categorie.title}/>))} 
            
             </div>

        <div className='divcategorias'>
        <div className='divtitulocategorias'>
                    <h1 className='titulo_blog_secundario'>Publicados Recientemente</h1>
                    <hr className="separadorgradienteabout"/>
                     </div> 

        {recentPost.map((recentPost)=>(<Recientes post={recentPost} key={recentPost.title}/>))} 
            
        
        </div>

        <div className='divcategorias'>
        <div className='divtitulocategorias'>
                    <h1 className='titulo_blog_secundario'>Nuestras Marcas</h1>
                    <hr className="separadorgradienteabout"/>
                     </div> 
         <div className='carouseldiv'>
         <MarcasCarousel></MarcasCarousel>
        </div>            
        
        </div>
             </div>
        

       
        

             
        </div>
        
        )}
        
       <Footer></Footer>
        </div>)
}

