import React from 'react';
import './About.css';
import imgsrc from './aboutimg.jpeg'
function About() {
    return (
        <div id="about">
            <div className="container-about">
                <div className="row">
                    <div className="col">
                    <img src={imgsrc} alt="imgab" className='imgabout'/>
                    </div>
                    <div className="col">
                        <div className="divdescription">
                            <h1 className="titudescription">Más Sobre Nosotros</h1>
                            <hr className="separadorgradienteabout"/>
                            <p className="paradescription">Agentes Biomédicos es una empresa dedicada a la prestación de servicios integrales para los prestadores de servicios de salud con una amplia oferta de productos y soluciones. Estas abarcan desde la comercialización de equipos médicos, soluciones en mantenimiento, calibración y reparación de equipos médicos hasta asesorias en habilitación. Con el objetivo de mejorar la calidad en la prestación de servicios de salud. </p>
                            <h2 className="subdescription">Porque contar con nosotros?</h2>
                            <div className="rowlista">
                                <div className="collista">
                                <ul className="ulabout">
                                    <li className="liabout">Cumplimiento Normativo.</li>
                                    <li className="liabout">Personal Certificado.</li>
                                    <li className="liabout">Servicios más seguros.</li>
                                  
                                
                                </ul>
                                </div>
                                <div className="collista">
                                <ul className="ulabout">
                                    <li className="liabout">Soluciones confiables. </li>
                                    <li className="liabout">Diferentes formas de pago.</li>
                                    <li className="liabout">Equipos Médicos de Calidad.</li>
                                
                                    
                                </ul>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
